.mobile-target-list {
    font-size: 12px; /* Reduced font size for better mobile rendering */
}

.mobile-table th,
.mobile-table td {
    font-size: 12px;
    text-align: center;
}

.action-icon {
    cursor: pointer;
    font-size: 16px;
    margin: 0 4px;
}

.clickable-link {
    cursor: pointer;
    text-decoration: underline;
}

.mobile-table th,
.mobile-table td {
    text-align: left;
}

.icon-action {
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .icon-add-search {
    color: #2563eb;
  }
  
  .icon-add-search:hover {
    color: #1e40af;
  }
  
  .icon-logout {
    color: #dc2626;
    margin-right: 10px;
    align-self: flex-end;
  }
  
  .icon-action:hover,
  .icon-logout:hover {
    color: #991b1b;
  }