/* Centering the entire login form */
.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Flexbox to align logo and text on the same line */
.logo-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.connectify-title {
  font-weight: bold;
  font-size: 2.5rem;
  font-family: 'Stylish Font', sans-serif; /* Use your stylish font here */
}

/* Input group styling */
.input-group {
  margin-bottom: 15px;
  width: 100%;
  max-width: 400px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
}

/* Checkbox wrapper for inline checkbox and label */
.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-top: 5px;
  white-space: nowrap;
}

.checkbox-wrapper input {
  margin-right: 4px; /* Adjust this value to create exactly 2 characters of spacing */
}

.checkbox-wrapper label {
  margin-left: 5px;
}

/* Button styling */
.auth-button {
  width: 100%;
  max-width: 200px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

/* Sign-up link styling */
.signup-link {
  margin-top: 15px;
}

.signup-link a {
  color: #007BFF;
  text-decoration: none;
}

/* Styling for signup form */
.signup-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.signup-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.auth-container .success-message {
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
}

.logo-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px; /* Adjust spacing between logo and form */
}

.logo {
  width: 20%; /* Adjust the width to 60% of the parent container */
  height: auto; /* Maintain aspect ratio */
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height to center vertically */
}