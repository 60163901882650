/* General styles for body and layout */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Main application styles */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Animation for logo */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Quill editor styles */
.ql-container {
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.ql-editor {
  min-height: 150px;
  max-height: 300px;
  box-sizing: border-box;
  padding: 10px;
  border: none;
  outline: none;
  overflow-y: auto;
  width: 100%;
}

/* Fix button overlap */
button {
  position: relative;
  z-index: 1;
}

/* Text wrapping and links */
.wrap-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 250px;
}

.clickable-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* Designation column with 30 characters width */
.designation-column {
  max-width: 30ch;
  white-space: normal;
  word-wrap: break-word;
}

/* Due Touch Connect column */
.dueTouchConnect-column {
  max-width: 10ch;
  white-space: normal;
  word-wrap: break-word;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.signup-form {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-table {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  margin-right: 15px;
}

.form-group:last-child {
  margin-right: 0;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input, select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.form-button {
  text-align: center;
  margin-top: 20px;
}

.auth-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.auth-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.login-link {
  text-align: center;
  margin-top: 20px;
}

.react-datepicker-popper {
  z-index: 1050 !important; /* Ensure it's above other elements like buttons */
}

.app {
  font-family: Arial, sans-serif;
}

.breadcrumb {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.breadcrumb-left {
  display: flex;
  align-items: center;
  gap: 15px;
}

.breadcrumb-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.bold {
  font-weight: bold;
}

.logo {
  height: 50px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  word-wrap: nowrap;
}

.column-layout {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.column {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.column img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.column p {
  text-align: justify;
}

.features-menu > span {
  font-size: 14pt; 
  margin-left: 40px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  color: blue;
  word-wrap: nowrap;
}

.features-menu > span {
  cursor: pointer;
  text-decoration: none;
}

.dropdown-content {
  margin-left: 40px;
  white-space: nowrap;
  min-width: 100px; /* Adjust this as needed */
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  display: none;
}

.dropdown-content.show {
  display: block;
}

.dropdown-content a {
  margin-left: 10px;
  text-decoration: none;
  color: #000;
  padding: 10px;
  display: block;
}

