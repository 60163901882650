.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .login-wrapper {
    width: 100%;
    max-width: 400px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .logo-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 60%;
    height: auto;
  }
  
  .login-form .form-group {
    margin-bottom: 15px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #f00b51;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup-link {
    margin-top: 15px;
    text-align: center;
    color: #f00b51;
  }
  
  .signup-link a {
    color: #f00b51;
    text-decoration: none;
  }
  
  @media screen and (max-width: 600px) {
    .login-wrapper {
      max-width: 90%;
      padding: 15px;
    }
  
    .logo {
      width: 70%;
    }
  }
  .checkbox-row {
    display: flex;
    align-items: center;
    gap: 8px; /* Spacing between checkbox and label */
    justify-content: flex-start; /* Left-align items */
    margin-top: 8px; /* Optional: Add space between password input and checkbox */
  }

  .checkbox-row {
    display: flex; /* Ensures items are inline */
    align-items: center; /* Vertically centers checkbox and label */
    gap: 3px; /* Precisely sets 3-character spacing between elements */
    justify-content: flex-start; /* Left-aligns the row */
    margin: 0; /* Removes any default margin */
    padding: 0; /* Removes padding for a clean layout */
  }
  
  .checkbox-row input[type="checkbox"] {
    margin: 0; /* Ensures no extra margin on checkbox */
    padding: 0; /* Resets any padding */
  }
  
  .checkbox-row label {
    margin: 0; /* Prevents unintended spacing */
    padding: 0; /* Removes padding for alignment */
    white-space: nowrap; /* Keeps "Show Password" text from wrapping */
  }