body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General styling for the application */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

h3 {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
}

/* Styling for buttons */
button {
  cursor: pointer;
}

/* Desktop-specific styling */
.desktop-table {
  width: 100%;
  border-collapse: collapse;
}

.desktop-table th,
.desktop-table td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.desktop-table th {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.desktop-table tr:hover {
  background-color: #f1f1f1;
}

/* Mobile-specific styling */
.mobile-container {
  padding: 10px;
}

.mobile-table {
  width: 100%;
  border-collapse: collapse;
}

.mobile-table th,
.mobile-table td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

.mobile-table th {
  background-color: #007bff;
  color: white;
}

.mobile-table td {
  font-size: 14px;
}

/* Search input field styling */
input[type="text"] {
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
}

/* Mobile header styling */
.mobile-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Logout button styling */
.logout-btn {
  background-color: #dc3545;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #c82333;
}