.date-time-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .date-input {
    width: 150px;
    padding: 5px;
    margin-right: 10px;
  }
  
  .date-picker {
    display: inline-block;
    margin-left: 5px;
  }
  
  .time-input {
    width: 100px;
    padding: 5px;
  }
  
  .event-input {
    flex-grow: 1;
    padding: 5px;
  }
  
  input::placeholder {
    font-style: italic;
    color: #aaa;
  }

  form label {
    font-weight: bold;
    margin-bottom: 5px;
}

form .form-control,
form .form-select {
    font-size: 0.9rem;
    padding: 5px;
    width: 100%;
}

form .form-control::placeholder {
    color: #999;
    font-style: italic;
}

button {
    padding: 5px 10px;
    font-size: 0.9rem;
}

.bell-icon {
  margin: 0 12px;
  color: darkorange;
  cursor: pointer;
  position: relative;
  font-size: 12px; /* Smaller bell icon container size */
}

.bell-icon-size {
  font-size: 16px; /* Specific size for the bell icon */
}

.reminder-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 1px 4px;
  font-size: 8px; /* Smaller text inside the circle */
  line-height: 1;
  min-width: 12px;
  min-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}